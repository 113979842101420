<template>
    <div class="w-full">
        <ConfirmationModal @success="getAll" />

        <ModalAssignAddress
            :visible="modalAssignVisible"
            :employeeUuid="employeeUuid"
            @refresh="getAll"
            @close="modalAssignVisible = false"
        />

        <div class="card flex justify-between border-b border-solid border-gray-200 pb-4 mb-5">
            <h1>{{ $t('employee_details.assigned_addresses') }}</h1>
        </div>
        <div class="card">
            <div class="flex justify-end pb-2 mb-2 border-b">
                <el-button type="primary" @click="showModalAssign">
                    {{ $t('add_address') }}
                </el-button>
            </div>
            <el-table :data="assignedAddressesData" class="w-full">
                <el-table-column label="Client">
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.client.name }} {{ scope.row.client.surname }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="Title" />
                <el-table-column label="Address" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <p>
                            {{ scope.row.street }}, {{ scope.row.post_code }} {{ scope.row.city }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column width="45">
                    <template slot-scope="scope">
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('unassign')" placement="top">
                            <el-button type="danger" size="mini" @click="unassign(scope.row.id)">
                                <fa-icon :icon="['fas', 'trash']" fixed-width />
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import Api from './assigned_addresses.api';

export default {
    components: {
        ModalAssignAddress: () => import(/* webpackChunkName: "EmployeeAssignAddresses/ModalAssignAddress" */ './ModalAssignAddress'),
    },

    data() {
        return {
            employeeUuid:          this.$route.params.uuid,
            assignedAddressesData: [],
            modalAssignVisible:    false,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'assigned_addresses');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.assignedAddressesData = await Api.getAll(this.employeeUuid);
        },

        async assign(addressId) {
            try {
                await Api.attach(this.employeeUuid, addressId);
                this.$notify.success({ title: this.$t('common.success') });
            } finally {
                this.getAll();
            }
        },

        async unassign(addressId) {
            try {
                await Api.detach(this.employeeUuid, addressId);
                this.$notify.success({ title: this.$t('common.success') });
            } finally {
                this.getAll();
            }
        },

        showModalAssign() {
            this.modalAssignVisible = true;
        },
    },
};
</script>

export default {
    async getAll(employeeUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${employeeUuid}/assigned_addresses`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: assigned_addresses.api.js ~ getAll ~ error', error);
            return [];
        }
    },

    async attach(employeeUuid, addressId) {
        try {
            const { data } = await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${employeeUuid}/assigned_addresses/${addressId}/attach`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: assigned_addresses.api.js ~ attach ~ error', error);
            return [];
        }
    },

    async detach(employeeUuid, addressId) {
        try {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${employeeUuid}/assigned_addresses/${addressId}/detach`);
        } catch (error) {
            console.log('🚀 ~ file: assigned_addresses.api.js ~ detach ~ error', error);
        }
    },
};
